<template>
  <SharedBasicsDefaultImage
    v-if="!logoLoading && serviceprovider.hasLogo"
    :class="[
      smallIcon ? 'w-8 h-8' : 'w-8 h-8',
      'flex-shrink-0 rounded-full mx-auto']"
    :source="picture"
    alt=""
  />
  <div
    v-else
    :class="[
      enrichedServiceprovider ? enrichedServiceprovider.bgColor : 'bg-red-light',
      smallIcon ? 'w-8 h-8' : 'w-10 h-10',
      'flex-shrink-0 flex items-center justify-center text-common-white text-md rounded-md font-bold mx-auto',
    ]"
  >
    {{ enrichedServiceprovider?.initials }}
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useNuxtApp, useRuntimeConfig } from '#app'
import { useCurrentServiceprovider } from '~/composables/states'
import { getBufferPictureToBase64 } from '~/utils/other'

const { serviceprovider } = useCurrentServiceprovider()
const { $toast, $logger, $axiosInstance } = useNuxtApp()

const envVars = useRuntimeConfig()

const loading = ref(true)
const logoLoading = ref(true)

const picture = ref('/logo-placeholder-image.webp')

const enrichedServiceprovider = computed(() => {
  return {
    title: serviceprovider.value.name,
    initials: serviceprovider.value.name.substring(0, 2),
    bgColor: 'bg-lilac-light'
  }
})

defineProps({
  smallIcon: {
    type: Boolean,
    default: false
  }
})

async function getLogo () {
  try {
    if (serviceprovider.value.hasLogo) {
      picture.value = await getBufferPictureToBase64(
        $axiosInstance,
        envVars.public.API_URL + `/serviceprovider/${serviceprovider.value.uuid}/whitelabel/logo`
      )
      logoLoading.value = false
    } else {
      logoLoading.value = false
    }

    loading.value = false // show screen to user
  } catch (e) {
    if (e.response) {
      $toast.warn('Warnung', 'Logo konnte nicht geladen werden')
    }
    $logger.error(new Error(`Logo konnte nicht geladen werden' ${e}`))
  }
}

onMounted(async () => {
  if (serviceprovider.value.dataLoaded) {
    await getLogo()
  }
})

watch(() => serviceprovider.value.dataLoaded, async () => {
  if (serviceprovider.value.dataLoaded) {
    await getLogo()
  }
})

</script>

<style scoped>

</style>
