<template>
  <BaseLayout>
    <template #primarySection>
      <section
        aria-labelledby="primary-heading"
        class="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-secondary p-10"
      >
        <slot class="mt-12" />

        <SharedCustomNotification />
      </section>
    </template>

    <template #secondarySection>
      <aside class="hidden lg:order-first lg:block lg:flex-shrink-0">
        <div
          class="relative flex h-full w-auto max-w-[15rem] flex-col overflow-y-auto"
        >
          <ProviderNavigation />
        </div>
      </aside>
    </template>
  </BaseLayout>
</template>

<script setup>
import BaseLayout from '~/components/shared/BaseLayout.vue'
import { AppException } from '~/utils/other'
import ProviderNavigation from '~/components/serviceprovider/ProviderNavigation.vue'

const { $logger, $gql } = useNuxtApp()
const { setServiceproviderName } = useCurrentServiceprovider()

const currentSpQuery = computed(() => {
  return `query serviceprovider {
    sp: tbl_serviceprovider {
      serviceprovider_name
    }
  }`
})

await getData()

async function getData () {
  try {
    const { data } = await $gql.query(currentSpQuery.value)

    setServiceproviderName(data?.sp?.[0]?.serviceprovider_name)
  } catch (e) {
    $logger.error(e)
    throw new AppException('Fehler beim Abrufen der Daten', 'Bitte versuchen Sie es später erneut.')
  }
}
</script>

<style></style>
